import {
  BadgeIcon,
  BookmarkIcon,
  ChannelsIcon,
  CommunityIcon,
  DirectMessagesIcon,
  EmojiPacksIcon,
  GoalIcon,
  ListsIcon,
  LiveStreamIcon,
  MapIcon,
  MuteIcon,
  SearchIcon,
  TorrentIcon,
  TrackIcon,
  WikiIcon,
} from "../../components/icons";
import { App } from "./component/app-card";
import ShieldOff from "../../components/icons/shield-off";
import Film02 from "../../components/icons/film-02";
import MessageQuestionSquare from "../../components/icons/message-question-square";
import UploadCloud01 from "../../components/icons/upload-cloud-01";
import Edit04 from "../../components/icons/edit-04";

export const internalApps: App[] = [
  {
    title: "Na żywo",
    description: "Oglądaj transmisje na żywo",
    icon: LiveStreamIcon,
    id: "streams",
    to: "/streams",
  },
  {
    title: "Społeczności",
    description: "Twórz i zarządzaj społecznościami",
    icon: CommunityIcon,
    id: "communities",
    to: "/communities",
  },
];

export const internalTools: App[] = [

  {
    title: "Moderacja",
    description: "Moderacja transmisji na żywo",
    icon: LiveStreamIcon,
    id: "stream-moderation",
    to: "/streams/moderation",
  }
];


export const allApps = [...internalApps, ...internalTools];
