import { useFormContext } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  FormControl,
  FormLabel,
  Switch,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  FormHelperText,
  Input,
  Select,
  Textarea,
  Link,
} from "@chakra-ui/react";

import { AppSettings } from "../../services/settings/migrations";
import { AppearanceIcon } from "../../components/icons";

export default function DisplaySettings() {
  const { register } = useFormContext<AppSettings>();

  return (
    <AccordionItem>
      <h2>
        <AccordionButton fontSize="xl">
          <AppearanceIcon mr="2" boxSize={5} />
          <Box as="span" flex="1" textAlign="left">
            Wyświetlanie
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <Flex direction="column" gap="4">
          <FormControl>
            <FormLabel htmlFor="theme" mb="0">
              Motyw
            </FormLabel>
            <Select id="theme" {...register("theme")} maxW="sm">
              <option value="default">Standard</option>
              <option value="chakraui">ChakraUI</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="colorMode" mb="0">
              Motyw
            </FormLabel>
            <Select id="colorMode" {...register("colorMode")} maxW="sm">
              <option value="system">Ustawienia systemu</option>
              <option value="light">Jasny motyw</option>
              <option value="dark">Ciemny motyw</option>
            </Select>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="primaryColor" mb="0">
              Podstawowy kolor
              </FormLabel>
              <Input id="primaryColor" type="color" maxW="120" size="sm" {...register("primaryColor")} />
            </Flex>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="maxPageWidth" mb="0">
              Maks. szerokość strony
            </FormLabel>
            <Select id="maxPageWidth" {...register("maxPageWidth")} maxW="sm">
              <option value="none">Brak</option>
              <option value="md">Średnia (~768px)</option>
              <option value="lg">Duża (~992px)</option>
              <option value="xl">Bardzo duża (~1280px)</option>
            </Select>
            <FormHelperText>
              <span>Ustawienie tej opcji ograniczy szerokość aplikacji na komputerze.</span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="blurImages" mb="0">
                Rozmycie mediów od nieznajomych
              </FormLabel>
              <Switch id="blurImages" {...register("blurImages")} />
            </Flex>
            <FormHelperText>
              <span>Włączone: zamazuje media od osób, których nie obserwujesz</span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="hideUsernames" mb="0">
                Ukrywanie nazw użytkowników (tryb anonimowy)
              </FormLabel>
              <Switch id="hideUsernames" {...register("hideUsernames")} />
            </Flex>
            <FormHelperText>
              <span>
                Włączone: ukrywa nazwy użytkowników i zdjęcia.{" "}
                <Link
                  as={RouterLink}
                  color="blue.500"
                  to="/n/nevent1qvzqqqqqqypzquuenyhl2svuk4tjqgj9l2xtnyyta28k3t8ay6zh3gvny544w2g2qy2hwumn8ghj7un9d3shjtnr0fshxtnc09az7qg0waehxw309a3h5ctn9e6x7up0qqsgayr2aj2f9gwq2c6shsjxufffrwtqfqth89g9gkntwv4ge8q37xq4muktu"
                >
                  Szczegóły
                </Link>
              </span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="removeEmojisInUsernames" mb="0">
                Ukryj emotikony w nazwach użytkowników
              </FormLabel>
              <Switch id="removeEmojisInUsernames" {...register("removeEmojisInUsernames")} />
            </Flex>
            <FormHelperText>
              <span>Włączone: Usuwa wszystkie emotikony w nazwach użytkowników.</span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="show-content-warning" mb="0">
                Pokaż ostrzeżenie dotyczące zawartości
              </FormLabel>
              <Switch id="show-content-warning" {...register("showContentWarning")} />
            </Flex>
            <FormHelperText>
              <span>Włączone: wyświetla ostrzeżenie dla notatek z ostrzeżeniem o treści NIP-36</span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="muted-words" mb="0">
              Wyciszone słowa
            </FormLabel>
            <Textarea
              id="muted-words"
              {...register("mutedWords")}
              placeholder="debil, seks, pierd..."
              maxW="2xl"
            />
            <FormHelperText>
              <span>
              Rozdzielona przecinkami lista słów, fraz lub hashtagów, których nigdy nie chcesz widzieć w notatkach. (wielkość liter nie ma znaczenia)

              </span>
              <br />
              <span>Jeśli dodasz popularne słowa, możesz łatwo ukryć wszystkie notatki.</span>
            </FormHelperText>
          </FormControl>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}
