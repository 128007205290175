import { useEffect, useRef, useState } from "react";
import { Avatar, Button, Flex, Heading, VisuallyHiddenInput } from "@chakra-ui/react";

import Fingerprint04 from "../../components/icons/fingerprint-04";
import { containerProps } from "./common";

export default function ProfileImageStep({
  displayName,
  onSubmit,
  onBack,
}: {
  displayName?: string;
  onSubmit: (picture?: File) => void;
  onBack: () => void;
}) {
  const [file, setFile] = useState<File>();
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const [preview, setPreview] = useState("");
  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setPreview(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [file]);

  return (
    <Flex gap="4" {...containerProps}>
      <Heading color={'teal.400'} size="s" mb="2">
        Wkrocz w nową erę mediów społecznościowych !
      </Heading>

      <Avatar
        as="button"
        size="xl"
        src={preview}        
        icon={<Fingerprint04 boxSize={12} />}
        autoFocus
      />
      <Heading size="md">{displayName}</Heading>
      <Button w="full" colorScheme="primary" maxW="sm" onClick={() => onSubmit(file)}>
        {file ? "Dalej" : "Pomiń"}
      </Button>
      <Button w="full" variant="link" onClick={onBack}>
        Wróć
      </Button>
    </Flex>
  );
}
