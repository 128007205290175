import { Box, Button, Card, Flex, Heading, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useAsync } from "react-use";
import TermsView from "../../views/signup/terms";
import { containerProps } from "./common";


export default function FinishedStep() {

  return (
    <TermsView />
  );
}
