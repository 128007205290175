import { useLocalStorage } from "react-use";
import {
  Flex,
  FormControl,
  FormLabel,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  FormHelperText,
  Input,
  Link,
  FormErrorMessage,
  Code,
  Switch,
  Select,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { safeUrl } from "../../helpers/parse";
import { AppSettings } from "../../services/settings/migrations";
import { createRequestProxyUrl } from "../../helpers/request";
import { SpyIcon } from "../../components/icons";
import { RelayAuthMode } from "../../classes/relay-pool";

async function validateInvidiousUrl(url?: string) {
  if (!url) return true;
  try {
    const res = await fetch(new URL("/api/v1/stats", url));
    return res.ok || "Cant reach instance";
  } catch (e) {
    return "Cant reach instance";
  }
}

async function validateRequestProxy(url?: string) {
  if (!url) return true;
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 2000);
    const res = await fetch(createRequestProxyUrl("https://example.com", url), { signal: controller.signal });
    return res.ok || "Cant reach instance";
  } catch (e) {
    return "Cant reach instance";
  }
}

export default function PrivacySettings() {
  const { register, formState } = useFormContext<AppSettings>();

  const [defaultAuthMode, setDefaultAuthMode] = useLocalStorage<RelayAuthMode>("default-relay-auth-mode", "ask", {
    raw: true,
  });

  return (
    <AccordionItem>
      <h2>
        <AccordionButton fontSize="xl">
          <SpyIcon mr="2" boxSize={5} />
          <Box as="span" flex="1" textAlign="left">
            Prywatność
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <Flex direction="column" gap="4">
          <FormControl>
            <FormLabel>Domyślne zachowanie podczas autoryzacji</FormLabel>
            <Select
              size="sm"
              w="xs"
              rounded="md"
              flexShrink={0}
              value={defaultAuthMode || "ask"}
              onChange={(e) => setDefaultAuthMode(e.target.value as RelayAuthMode)}
            >
              <option value="always">Zawsze uwierzytelniaj</option>
              <option value="ask">Pytaj za każdym razem</option>
              <option value="never">Nigdy nie uwierzytelniaj</option>
            </Select>
            <FormHelperText>Jak aplikacja powinna obsługiwać transmitery żądające identyfikacji?</FormHelperText>
          </FormControl>


          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="loadOpenGraphData" mb="0">
                Ładowanie danych Open Graph
              </FormLabel>
              <Switch id="loadOpenGraphData" {...register("loadOpenGraphData")} />
            </Flex>
            <FormHelperText>
              <span>
                Czy ładować dane{" "}
                <Link href="https://ogp.me/" isExternal color="blue.500">
                  Open Graph
                </Link>{" "}
                dla linków?
              </span>
            </FormHelperText>
          </FormControl>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}
