import { safeRelayUrl, safeRelayUrls } from "./helpers/relay";

export const SEARCH_RELAYS = safeRelayUrls([
  "wss://prl.plus",
  // TODO: requires NIP-42 auth
  // "wss://filter.nostr.wine",
]);
export const WIKI_RELAYS = safeRelayUrls(["wss://prl.plus/"]);
export const COMMON_CONTACT_RELAY = safeRelayUrl("wss://prl.plus") as string;
export const COMMON_CONTACT_RELAYS = [COMMON_CONTACT_RELAY];
