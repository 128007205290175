import { useFormContext } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormLabel,
  Switch,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  FormHelperText,
  Input,
  Link,
  FormErrorMessage,
  Select,
  Button,
  Text,
} from "@chakra-ui/react";
import { useLocalStorage } from "react-use";

import { safeUrl } from "../../helpers/parse";
import { AppSettings } from "../../services/settings/migrations";
import { PerformanceIcon } from "../../components/icons";
import { selectedMethod } from "../../services/verify-event";

function VerifyEventSettings() {
  const [verifyEventMethod, setVerifyEventMethod] = useLocalStorage<string>("verify-event-method", "internal", {
    raw: true,
  });

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="verifyEventMethod" mb="0">
          Zweryfikuj metodę zdarzenia
        </FormLabel>
        <Select value={verifyEventMethod} onChange={(e) => setVerifyEventMethod(e.target.value)} maxW="sm">
          <option value="wasm">WebAssembly</option>
          <option value="internal">Wewnętrzna</option>
          <option value="none">Brak</option>
        </Select>
        <FormHelperText>Domyślnie: Wszystkie sygnatury zdarzeń są sprawdzane.</FormHelperText>
        <FormHelperText>WebAssembly: Sygnatury zdarzeń są sprawdzane w osobnym wątku</FormHelperText>
        <FormHelperText>Brak: Sprawdzane są tylko profile, obserwowani i wymienne sygnatury zdarzeń.</FormHelperText>

        {selectedMethod !== verifyEventMethod && (
          <>
            <Text color="blue.500" mt="2">
              NOTE: You must reload the app for this setting to take effect
            </Text>
            <Button colorScheme="primary" size="sm" onClick={() => location.reload()}>
              Reload App
            </Button>
          </>
        )}
      </FormControl>
    </>
  );
}

export default function PerformanceSettings() {
  const { register, formState } = useFormContext<AppSettings>();

  return (
    <AccordionItem>
      <h2>
        <AccordionButton fontSize="xl">
          <PerformanceIcon mr="2" boxSize={5} />
          <Box as="span" flex="1" textAlign="left">
            Parametry
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <Flex direction="column" gap="4">
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="proxy-user-media" mb="0">
                Proxy media użytkownika
              </FormLabel>
              <Switch id="proxy-user-media" {...register("proxyUserMedia")} />
            </Flex>
            <FormHelperText>
              <span>Jeżeli włączone: Użyj media.nostr.band, aby uzyskać mniejsze zdjęcia profilowe (oszczędność ~50Mb danych)</span>
              <br />
              <span>Efekt uboczny: Niektóre zdjęcia użytkowników mogą się nie ładować lub mogą być nieaktualne.</span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="imageProxy" mb="0">
            Usługa proxy dla obrazów
            </FormLabel>
            <Input
              id="imageProxy"
              type="url"
              {...register("imageProxy", {
                setValueAs: (v) => safeUrl(v) || v,
              })}
            />
            {formState.errors.imageProxy && <FormErrorMessage>{formState.errors.imageProxy.message}</FormErrorMessage>}
            <FormHelperText>
              <span>
                Adres URL do serwer proxy{" "}
              </span>
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="autoShowMedia" mb="0">
                Pokaż osadzone elementy
              </FormLabel>
              <Switch id="autoShowMedia" {...register("autoShowMedia")} />
            </Flex>
            <FormHelperText>Jeżeli wyłączone: Embeds pokaże przycisk rozszerzalny</FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="showReactions" mb="0">
                Pokaż reakcje
              </FormLabel>
              <Switch id="showReactions" {...register("showReactions")} />
            </Flex>
            <FormHelperText>Jeżeli włączone: Wyświetlanie reakcji na wpisy</FormHelperText>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="autoDecryptDMs" mb="0">
                Automatycznie odszyfruj DM
              </FormLabel>
              <Switch id="autoDecryptDMs" {...register("autoDecryptDMs")} />
            </Flex>
            <FormHelperText>Jeżeli włączone: automatyczne odszyfrowywanie wiadomości bezpośrednich</FormHelperText>
          </FormControl>
          <VerifyEventSettings />
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}
