import { NostrEvent } from "../../../types/nostr-event";
import { DotsMenuButton, MenuIconButtonProps } from "../../../components/dots-menu-button";
import DeleteEventMenuItem from "../../../components/common-menu-items/delete-event";
import CopyEmbedCodeMenuItem from "../../../components/common-menu-items/copy-embed-code";


export default function EmojiPackMenu({
  pack,
  ...props
}: { pack: NostrEvent } & Omit<MenuIconButtonProps, "children">) {
  return (
    <>
      <DotsMenuButton {...props}>
      
        <CopyEmbedCodeMenuItem event={pack} />
        <DeleteEventMenuItem event={pack} label="Delete Pack" />
        </DotsMenuButton>
    </>
  );
}
