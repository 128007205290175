import { useAsync } from "react-use";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CacheRelay } from "nostr-idb";
import { Link as RouterLink } from "react-router-dom";

import BackButton from "../../../components/router/back-button";
import { NOSTR_RELAY_TRAY_URL, checkNostrRelayTray, localRelay } from "../../../services/local-relay";
import WasmRelay from "../../../services/wasm-relay";
import { ChevronDownIcon, ChevronUpIcon } from "../../../components/icons";
import MemoryRelay from "../../../classes/memory-relay";

function InternalRelay() {
  const enabled = localRelay instanceof CacheRelay;
  const enable = () => {
    localStorage.setItem("localRelay", "nostr-idb://internal");
    location.reload();
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">
      <CardHeader p="4" display="flex" gap="2" alignItems="center">
        <Heading size="md">Pamięć podręczna przeglądarki</Heading>
        <Button size="sm" colorScheme="primary" ml="auto" onClick={enable} isDisabled={enabled}>
          {enabled ? "Włączone" : "Włącz"}
        </Button>
      </CardHeader>
      <CardBody p="4" pt="0">
        <Text mb="2">Użyj wbudowanej bazy danych przeglądarki do buforowania zdarzeń.</Text>
        <Text>Maksymalna pojemność: 10 tys. wydarzeń</Text>
        <Text>Wydajność: Nawet niezła, ale ograniczona przez przeglądarkę</Text>
      </CardBody>
      {enabled && (
        <CardFooter p="4" pt="0">
          <Button size="sm" colorScheme="primary" ml="auto" as={RouterLink} to="/relays/cache/database">
            Opcje bazy danych
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}

function WasmWorkerRelay() {
  const enabled = localRelay instanceof WasmRelay;
  const enable = () => {
    localStorage.setItem("localRelay", "nostr-idb://wasm-worker");
    location.reload();
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">

      {enabled && (
        <CardFooter p="4" pt="0">
          <Button size="sm" colorScheme="primary" ml="auto" as={RouterLink} to="/relays/cache/database">
            Opcje bazy danych
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}

function NostrRelayTray() {
  const { value: available, loading: checking } = useAsync(checkNostrRelayTray);

  const enabled = localRelay?.url.startsWith(NOSTR_RELAY_TRAY_URL);
  const enable = () => {
    localStorage.setItem("localRelay", NOSTR_RELAY_TRAY_URL);
    location.reload();
  };
}

function CitrineRelay() {
  const { value: available, loading: checking } = useAsync(checkNostrRelayTray);

  const enabled = localRelay?.url.startsWith(NOSTR_RELAY_TRAY_URL);
  const enable = () => {
    localStorage.setItem("localRelay", NOSTR_RELAY_TRAY_URL);
    location.reload();
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">
      <CardHeader p="4" display="flex" gap="2" alignItems="center">
        <Heading size="md">Citrine</Heading>
        <Link color="blue.500" href="https://github.com/greenart7c3/Citrine" isExternal>
          GitHub
        </Link>
        {available ? (
          <Button size="sm" colorScheme="primary" ml="auto" isLoading={checking} onClick={enable} isDisabled={enabled}>
            {enabled ? "Włączone" : "Włącz"}
          </Button>
        ) : (
          <Button
            as={Link}
            isExternal
            href="https://github.com/greenart7c3/Citrine"
            colorScheme="blue"
            size="sm"
            ml="auto"
          >
            Pobierz aplikację
          </Button>
        )}
      </CardHeader>
      <CardBody p="4" pt="0">
        <Text mb="2">Fajna mała aplikacja, która uruchamia lokalny transmiter w telefonie</Text>
        <Text>Maksymalna pojemność: Nieograniczona</Text>
        <Text>Wydajność: Tak szybki jak Twój telefon</Text>
      </CardBody>
    </Card>
  );
}

function SatelliteRelay() {
  const { value: relay } = useAsync(() => window.satellite!.getLocalRelay());
  const { value: enabled } = useAsync(async () => localRelay?.url === relay, [localRelay?.url, relay]);
  const enable = () => {
    if (relay) {
      localStorage.setItem("localRelay", relay);
      location.reload();
    }
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">
      <CardHeader p="4" display="flex" gap="2" alignItems="center">
        <Heading size="md">Satellite Transmiter</Heading>
        <Button size="sm" colorScheme="primary" ml="auto" onClick={enable} isDisabled={enabled}>
          {enabled ? "Włączone" : "Włącz"}
        </Button>
      </CardHeader>
      <CardBody p="4" pt="0">
        <Text mb="2">Pulpit Satellite udostępnia lokalny transmiter buforowania, który może być używany do przechowywania zdarzeń użytkownika</Text>
        <Text>Maksymalna pojemność: Nieograniczona</Text>
        <Text>Wydajność: Tak szybki jak szybki jest komputer</Text>
      </CardBody>
    </Card>
  );
}

function HostedRelay() {
  const enabled = localRelay?.url.includes(location.host + "/local-relay");
  const enable = () => {
    localStorage.removeItem("localRelay");
    location.reload();
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">
      <CardHeader p="4" display="flex" gap="2" alignItems="center">
        <Heading size="md">Hostowany transmiter</Heading>
        <Button size="sm" colorScheme="primary" ml="auto" onClick={enable} isDisabled={enabled}>
          {enabled ? "Włączone" : "Włącz"}
        </Button>
      </CardHeader>
      <CardBody p="4" pt="0">
        <Text mb="2">Twoja instalacja Klepak jest skonfigurowana z lokalnym transmiterem, który może być używany jako pamięć podręczna</Text>
        <Text>Maksymalna pojemność: Nieznana</Text>
        <Text>Efektywność: Nieznana, ale prawdopodobnie duża...</Text>
      </CardBody>
    </Card>
  );
}

function InMemoryRelay() {
  const enabled = localRelay instanceof MemoryRelay;
  const enable = () => {
    localStorage.setItem("localRelay", ":memory:");
    location.reload();
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">
      <CardHeader p="4" display="flex" gap="2" alignItems="center">
        <Heading size="md">Pamięć podręczna</Heading>
        <Button size="sm" colorScheme="primary" ml="auto" onClick={enable} isDisabled={enabled}>
          {enabled ? "Włączone" : "Włącz"}
        </Button>
      </CardHeader>
      <CardBody p="4" pt="0">
        <Text mb="2">Przechowuje wszystkie zdarzenia w pamięci</Text>
        <Text>Maksymalna pojemność: Nieograniczona, dopóki Twój system się nie zawiesi</Text>
        <Text>Wydajność: Bardzo szybka</Text>
        <Text color="yellow.500">UWAGA: Wszystkie zdarzenia zostaną zapomniane po zamknięciu aplikacji.</Text>
      </CardBody>
      {enabled && (
        <CardFooter p="4" pt="0">
          <Button size="sm" colorScheme="primary" ml="auto" as={RouterLink} to="/relays/cache/database">
          Opcje bazy danych
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}

function NoLocalRelay() {
  const enabled = localRelay === null;
  const enable = () => {
    localStorage.setItem("localRelay", ":none:");
    location.reload();
  };

  return (
    <Card borderColor={enabled ? "primary.500" : undefined} variant="outline">
      <CardHeader p="4" display="flex" gap="2" alignItems="center">
        <Heading size="md">Bez pamięci podręcznej</Heading>
        <Button size="sm" colorScheme="primary" ml="auto" onClick={enable} isDisabled={enabled}>
          {enabled ? "Włączone" : "Włącz"}
        </Button>
      </CardHeader>
      <CardBody p="4" pt="0">
        <Text mb="2">Brak lokalnego transmitera, nic nie jest buforowane</Text>
        <Text>Maksymalna pojemność: 0</Text>
        <Text>Wydajność: Tak szybko, jak transmitery, z którymi się łączysz</Text>
        <Text color="blue.500">UWAGA: Profile i osie czasu są nadal przechowywane w pamięci podręcznej.</Text>
      </CardBody>
    </Card>
  );
}

export default function CacheRelayView() {
  const showAdvanced = useDisclosure({ defaultIsOpen: localRelay?.url === ":none:" || localRelay?.url === ":memory:" });

  return (
    <Flex gap="2" direction="column" flex={1}>
      <Flex gap="2" alignItems="center">
        <BackButton hideFrom="lg" size="sm" />
        <Heading size="lg">Pamięć podręczna</Heading>
      </Flex>
      <Text fontStyle="italic" mt="-2" px={{ base: "2", lg: 0 }}>
        Transmiter pamięci podręcznej służy do lokalnego buforowania zdarzeń, dzięki czemu można je szybko załadować
      </Text>
      <InternalRelay />
      {WasmRelay.SUPPORTED && <WasmWorkerRelay />}
      {window.satellite && <SatelliteRelay />}
      {window.CACHE_RELAY_ENABLED && <HostedRelay />}
      <Button w="full" variant="link" p="4" onClick={showAdvanced.onToggle}>
        <Divider />
        <Box as="span" ml="4" mr="2">
          Zaawansowane
        </Box>
        {showAdvanced.isOpen ? <ChevronUpIcon boxSize={5} mr="2" /> : <ChevronDownIcon boxSize={5} mr="2" />}
        <Divider />
      </Button>
      {showAdvanced.isOpen && (
        <>
          <InMemoryRelay />
          <NoLocalRelay />
        </>
      )}
    </Flex>
  );
}
