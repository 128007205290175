import { useState } from "react";
import { Box, Heading, Container, Text, Button, Stack, Icon, useColorModeValue,
  createIcon, Flex, FormControl, FormHelperText, FormLabel, Input, Link, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { RelayUrlInput } from "../../components/relay-url-input";
import { normalizeToHexPubkey } from "../../helpers/nip19";
import accountService from "../../services/account";
import { COMMON_CONTACT_RELAY } from "../../const";
import QRCodeScannerButton from "../../components/qr-code/qr-code-scanner-button";

export default function TermsView() {
  const navigate = useNavigate();
  const toast = useToast();
  const [npub, setNpub] = useState("");
  const [relayUrl, setRelayUrl] = useState(COMMON_CONTACT_RELAY);

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  return (
    <Container maxW={'3xl'}>
    <Stack
          as={Box}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading textAlign={'center'}
            fontWeight={600}
            lineHeight={'110%'}>
            Regulamin określający zasady korzystania z serwisu społecznościowego <br />
            <Text  as={'span'} >
               KLEPAK
            </Text>
          </Heading>
          <Text> Obowiązuje od 18 czerwca 2024 r
        </Text>
          <Text color={'BlueGray.500'}>  Witamy na polskim portalu społecznościowym Klepak. Głównym celem projektu jest obrona polskiej rodziny i tradycyjnych
          <Link href='https://calapolskaczytadzieciom.pl/dla-edukatorow/nauczanie-wartosci/uniwersalne-wartosci-moralne/' target='...' >
           {" "} wartości moralnych.{""}
          </Link> <Text as='b'>Jesteśmy tolerancyjni, ale tolerancja musi mieć granice... </Text>
            Będziemy szczęśliwi, jeśli przyczynimy się do powstrzymania degradacji polskiego społeczeństwa.
             Bardzo ważne w takim przypadku jest poczucie sprawiedliwości i wolności w społeczeństwie. Wolność wymaga odporności na cenzurę polityczną a Klepak właśnie to zapewnia.
              Klepak korzysta ze zdecentralizowanej i otwartej sieci społecznościowej PRL+, opartej na protokole Nostr.
              Dzięki PRL+ twoje interakcje społeczne są chronione przed wszelkimi centro kontrolami, co zapewnia, platformę wolną od reklam, toksycznych algorytmów i cenzury.
              Jeżeli uważasz że twoje dobra osobiste zostały rażąco naruszone możesz to zgłosić do administratora
               <Link href='https://www.prl.plus' > sieci PRL+</Link>.
          </Text>

          <Text >
          <p><b>Polityka Prywatności</b></p>
          Prywatność w internecie to fikcja. Prawo do prywatności działa tylko w realu.
          Jeśli chcesz zachować pozory prywatności to <b>nigdy na żadnym serwisie nie podawaj swoich prawdziwych danych !</b>
          </Text >
          <Text > Witryna klepak.net nie zbiera ani nie przetwarza żadnych danych osobowych od swoich użytkowników. Aplikacja służy do łączenia się z zewnętrznymi serwerami Nostr (zwanymi również transmiterami), które mogą gromadzić dane osobowe i nie są objęte niniejszą Polityką Prywatności.
          <Text >Każdy transmiter innej firmy jest wyposażony w własną politykę prywatności i warunki użytkowania, które można przeglądać za pośrednictwem aplikacji lub strony internetowej tego serwera.
          <Text>Twórcy portalu Klepak nie mają dostępu do danych znajdujących się w telefonie użytkownika. Konta są w pełni zarządzane przez użytkownika. Nie mamy do nich dostępu, ani możlowości kontroli.</Text>
          </Text >
          <Text >
           Aplikacja webowa Klepak może zapisywać na urządzenie token, klucz publiczny i preferowany transmiter, aby świadczyć usługi powiadomień push. Poza tym dane z podłączonych kont są przechowywane lokalnie na urządzeniu tylko wtedy, gdy jest to wymagane dla funkcjonalności i wydajności Klepaka. Dane te są ściśle poufne i nie mogą być dostępne dla innych aplikacji (na urządzeniach nierootowanych). Dane te można usunąć, czyszcząc lokalną pamięć Klepak lub odinstalowując aplikację.
           <Text>Klepak oferuje kilka opcji przesyłania zdjęć i filmów w celu publikowania ich online. Możesz wybrać media serwer według własnego uznania. Podobnie jak transmitery, takie usługi są niezależne od witryny Klepak i mają własną politykę prywatności i warunki użytkowania.</Text>
          </Text>
          </Text>
          <Text><b> Trwałość Informacji</b>
          <p/>Informacje udostępniane na Nostr powinny być traktowane jako stałe dla celów ochrony prywatności. Nie ma możliwości zagwarantowania usunięcia lub edycji jakichkolwiek treści po opublikowaniu.
          </Text>

          <Text ><b>Warunki użytkowania</b>
          <p/> Każdy Użytkownik odpowiada prawnie za swoje wypowiedzi.
          Administracja portalu nie ponosi odpowiedzialności za publikowane materiały.
          <p/>
          Nie możesz używać witryny Klepak do przesyłania budzących zastrzeżenia treści do transmiterów. Treści budzące zastrzeżenia obejmują między innymi: (1) materiały o charakterze jednoznacznie seksualnym; (2) obsceniczne, zniesławiające, oszczercze, oszczercze, brutalne i/lub niezgodne z prawem treści lub wulgaryzmy; (3) treści naruszające prawa osób trzecich, w tym prawa autorskie, znaki towarowe, prywatność, reklamę lub inne prawa osobiste lub majątkowe, lub które są zwodnicze lub oszukańcze; (4) treści promujące używanie lub sprzedaż nielegalnych lub regulowanych substancji, wyrobów tytoniowych, amunicji i / lub broni palnej; oraz (5) nielegalne treści związane z hazardem.

Nie kontrolujemy treści publikowanych za pomocą transmiterów Noster. Wszelkie kwestie prawne powinny zostać rozwiązane pomiędzy użytkownikiem a operatorem transmitera.
          </Text>
          <Text >
          <b>Linki do innych stron</b>
<p>Witryna może zawierać linki lub linki do informacji, które nie zostały opracowane, zweryfikowane lub przetestowane przez nas lub któregokolwiek z naszych pracowników lub agentów. Są one dostarczane wyłącznie w celach informacyjnych. Nie ponosimy żadnej odpowiedzialności za te informacje ani nie potwierdzamy ich dokładności ani kompletności. Nie gwarantujemy, że informacje lub udostępnianie hiperłączy nie narusza praw osób trzecich.
 Nie ponosimy odpowiedzialności za nie ani za jakiekolwiek dostępne treści, ani za jakiekolwiek straty lub szkody, które mogą wyniknąć z ich wykorzystania. </p>
</Text>

<Text>
Klepak jest niezależnym i bezstronnym portalem nie popierającym żadnego ugrupowania politycznego oraz nie otrzymującym żadnych dóbr materialnych od instytucji oraz osób obcego obywatelstwa.
Korzystając z serwisu, zgadzasz się zgadzasz się z warunkami niniejszego regulaminu oraz z tym że wyłączenia odpowiedzialności określone w niniejszym dokumencie są rozsądne i uzasadnione. Jeśli nie uważasz ich za uzasadnione, nie powinieneś korzystać z witryny klepak.net!
</Text>
<Text>Zastrzegamy sobie prawo do zmiany niniejszej Polityki Prywatności i Warunków Użytkowania w dowolnym momencie. Wszelkie modyfikacje niniejszego dokumentu będą obowiązywać z chwilą opublikowania przez nas nowych warunków i / lub wprowadzenia nowych zmian na stronie (lub w inny sposób wskazany w momencie publikowania). We wszystkich przypadkach dalsze korzystanie z aplikacji webowej po opublikowaniu jakiejkolwiek zmodyfikowanej polityki prywatności i Warunków użytkowania oznacza akceptację warunków zmodyfikowanej polityki prywatności i/lub Warunków Użytkowania.
</Text>

          <Stack
            direction={'column'}
            spacing={3}
            position={'relative'}>
            <Box>
              <Text
                fontSize={'lg'}
                fontFamily={'Caveat'}
                position={'absolute'}
                right={'-5px'}
                top={'-15px'}
                transform={'rotate(10deg)'}>
                Akceptuję niniejszy regulamin !
              </Text>
            </Box>
            <Flex justifyContent="space-between" gap="2">
            <Link href='https://pl1.tv' >
             <Button opacity={0.8}>
                Opuść stronę
                </Button>
             </Link>
              <Button colorScheme={'green'}
              bg={'green.400'}
              rounded={'full'} onClick={() => navigate("../../../signin")}>
            Wchodzę w to
              </Button>
            </Flex>
          </Stack>
        </Stack>
    </Container>
  );
}
